import React from 'react';

import Layout from '../components/Layout/Layout';
import Services from '../components/Services';
import ProjectsFaq from '../components/ProjectsFaq';
import Contact from '../components/Contact';
import About from '../components/About';
import TerrierLink from '../components/TerrierLink';
import Header from '../components/Header';
import SEO from '../components/seo';

import '../styles/styles.scss';

const IndexPage = () => (
  <Layout>
    <SEO title="Georeal" />
    <Header />
    <About />
    <Services />
    <ProjectsFaq />
    <Contact />
    <TerrierLink />
  </Layout>
);

export default IndexPage;
