import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import contactImg from '../images/contact.png';
import ContactForm from './ContactForm';

const Contact = () => (
  <section style={{ backgroundImage: `url(${contactImg})` }} className="contact">
    <Container>
      <Row>
        <Col md={6}>
          <ContactForm />
        </Col>
      </Row>
    </Container>
  </section>
);

export default Contact;
