/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { slide as Menu } from 'react-burger-menu';
import logo from '../../images/logo.png';

const styles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '36px',
    height: '30px',
    left: 'initial',
    right: '36px',
    top: '25px',
  },
  bmBurgerBars: {
    background: 'white',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
  },
  bmMenu: {
    background: '#f5f5f5',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
  },
  bmItem: {
    display: 'block',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    alignItems: 'center',
    textAlign: 'center',
    paddingBottom: '2.5rem',
    letterSpacing: '0.15px',
    color: '#000000',
    textDecoration: 'none',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = { menuOpen: false };
  }

  loginClick() {
    this.setState({ menuOpen: false });
  }

  render() {
    const { menuOpen } = this.state;
    return (
      <>
        <Container>
          <Row style={{ position: 'relative' }} className="justify-content-end">
            <Col style={{ position: 'absolute' }} md={12}>
              <img style={{ marginTop: '-7px' }} src={logo} width={150} height={84} alt="logo" />
            </Col>
            <Col
              style={{ position: 'absolute', textAlign: 'right' }}
              className="mt-3 pr-0 pl-0 responsive-hidden"
              md={12}>
              <AnchorLink href="#contact" className="anchor-button" style={{ height: '40px' }}>
                Kontaktujte nás
              </AnchorLink>
            </Col>
            <Col className="ownnav pt-3 pb-3" md={12} id="link">
              <AnchorLink href="#about">O nás</AnchorLink>
              <AnchorLink href="#services">Služby</AnchorLink>
              <AnchorLink href="#projects">Projekty</AnchorLink>
              <AnchorLink href="#faq">FAQ</AnchorLink>
              <AnchorLink href="#contact">Kontakt</AnchorLink>
            </Col>
          </Row>
        </Container>
        <div className="responsive-shown">
          <Menu isOpen={menuOpen} styles={styles}>
            <AnchorLink onClick={this.loginClick.bind(this)} className="menu-item" href="#about">
              O nás
            </AnchorLink>
            <AnchorLink onClick={this.loginClick.bind(this)} className="menu-item" href="#services">
              Služby
            </AnchorLink>
            <AnchorLink onClick={this.loginClick.bind(this)} className="menu-item" href="#projects">
              Projekty
            </AnchorLink>
            <AnchorLink onClick={this.loginClick.bind(this)} className="menu-item" href="#faq">
              FAQ
            </AnchorLink>
            <AnchorLink
              onClick={this.loginClick.bind(this)}
              href="#contact"
              className="anchor-button"
              style={{ height: '40px', paddingTop: '19px', color: 'white' }}>
              Kontakt
            </AnchorLink>
          </Menu>
        </div>
      </>
    );
  }
}

export default NavBar;
