import React from 'react';
import markRed from '../../images/mark-red.svg';
import markWhite from '../../images/mark-white.svg';

const Marker = ({ description, coloor }) => (
  <div className="section-marker">
    <img
      className={`mr-3 marker-${coloor}`}
      src={coloor === '#E20000' ? markRed : markWhite}
      width={18}
      height={15}
      alt={`mark ${description}`}
    />
    <p style={{ color: coloor }}>{description}</p>
  </div>
);

export default Marker;
