import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ServicesTile from './ServicesTile';
import Headers from './includes/Headers';
import img1 from '../images/services/1.svg';
import img2 from '../images/services/2.svg';
import img3 from '../images/services/3.svg';
import Marker from './includes/Marker';

const Services = () => (
  <section id="services" className="services">
    <Container>
      <Row className="pt-3 pb-5">
        <Marker description="NAŠE SLUŽBY" coloor="#E20000" />
        <Headers title="Nami ponúkané služby" coloor="black" />
        <ServicesTile
          title="Kataster Nehnuteľností"
          description="Vytýčenie hraníc pozemkov, adresný bod, grafická a písomná identifikácia vlastníctva."
          img={img1}
        />
        <ServicesTile
          title="Geometrický Plán"
          description="Zameranie stavieb, rozdelenie pozemkov, určenie vlastníckych pomerov, zriadenie vecného bremena. Závisí od mapového podkladu, polohy..."
          img={img2}
        />
        <ServicesTile
          title="Inžinierska Geodézia"
          description="Bodové polia, polohopisné a výškopisné zameranie, pozdĺžne a priečne profily, vytýčenie stavebných objektov."
          img={img3}
        />
        <Col md={12} style={{ textAlign: 'center' }}>
          <AnchorLink href="#contact" className="anchor-button">
            Zistiť presnú sumu
          </AnchorLink>
        </Col>
      </Row>
    </Container>
  </section>
);

export default Services;
