import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Marker from './includes/Marker';
import aboutImg from '../images/aboutimg.png';

const aboutText = (
  <p>
    Spoločnosť GeoReal pôsobí na trhu už 10 rokov. Skúsenosti našich zakladateľov siahajú od čias
    logaritmických pravítok a kalkulačiek až po prácu s najmodernejšou GNSS technológiou. Tieto
    skúsenosti predávajú mladému a energickému tímu profesionálov, ktorí uprednostňujú kvalitne
    vykonanú prácu pred cenovo lákavou ponukou. Našim hlavným produktom je geometrický plán.
    Priemerná dĺžka dodania geometrického plánu do Vašich rúk je 7 pracovných dní od dňa objednania.
    Konateľ spoločnosti Ing. Jozef Kaščák je{' '}
    <mark className="header-mark"> AUTORIZOVANÝ GEODET</mark>
    oprávnený overovať vybrané geodetické činnosti.
  </p>
);

const About = () => (
  <section style={{ marginTop: '-100px' }}>
    <Container>
      <Row className=" justify-content-center">
        <Col id="about" className="about" md={12}>
          <Row className="justify-content-center">
            <Col md={10}>
              <Marker description="KTO SME" coloor="white" />
              <h2 className="pb-3" style={{ color: 'white' }}>
                Niečo o nás
              </h2>
              {aboutText}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={10} className="about-img">
          <img alt="aboutimage" src={aboutImg} />
        </Col>
      </Row>
    </Container>
  </section>
);

export default About;
