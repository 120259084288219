import React from 'react';
import { Col } from 'react-bootstrap';

const ProjectsRow = ({ title, description, img }) => (
  <Col className="pt-4 pb-4 projects-row" md={3}>
    <h3>{title}</h3>
    <p>{description}</p>
    <img alt={description} src={img} />
  </Col>
);

export default ProjectsRow;
