import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Col, Container, Row } from 'react-bootstrap';
import headerImg from '../images/hero.png';

const Header = () => (
  <section className="hero" style={{ backgroundImage: `url(${headerImg})` }}>
    <Container>
      <Row>
        <Col className="header-col" md={12}>
          <h1 className="header-text">
            My sme
            <mark className="header-mark"> GeoReal</mark>
          </h1>
          <h3 style={{ color: 'white' }} className="pb-5">
            {' '}
            Geodetické služby v Prešove
          </h3>
          <AnchorLink href="#services" className="anchor-button">
            Ponúkané služby
          </AnchorLink>
        </Col>
      </Row>
    </Container>
  </section>
);

export default Header;
