import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ReactPlayer from 'react-player';

const TerrierLink = () => (
  <section className="terrier">
    <Container className="terrier-container pl-5 pr-5 pt-5 pb-5">
      <Row>
        <Col md={12}>
          <h2>Čo je mapka?</h2>
          <h5 className="pb-5">
            <a
              style={{ color: '#E20000' }}
              href="https://zbgis.skgeodesy.sk/mkzbgis/Help/sk/"
              target="_blank"
              rel="noopener noreferrer">
              Prečítajte si o bližšie informácie, ako využívať tento nástroj
            </a>{' '}
            alebo si pozrite video.
          </h5>
          <ReactPlayer
            url="https://zbgis.skgeodesy.sk/mkzbgis/Help/sk/video/Videomanual_KN_final.mp4"
            controls
            width="100%"
            height="auto"
          />
        </Col>
        <Col md={6} className="pt-5">
          <h3>Pozrite si pozemok na MAPke</h3>
        </Col>
        <Col md={6} className="terrier-button pt-5">
          <Button
            target="_blank"
            href="https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster/"
            rel="noopener noreferrer"
            className="mt-0 submit-button">
            Prejsť na mapka.gku.sk
          </Button>
        </Col>
      </Row>
    </Container>
  </section>
);

export default TerrierLink;
