import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GoogleMaps from './map';
import FooterContact from './FooterContact';
import logo from '../../images/logo.png';
import place from '../../images/footer/place.svg';
import smartphone from '../../images/footer/smartphone.svg';
import mail from '../../images/footer/email.svg';

const About = (
  <p>
    Spoločnosť GeoReal pôsobí na trhu už 10 rokov. Skúsenosti našich zakladateľov siahajú od čias
    logaritmických pravítok a kalkulačiek až po prácu s najmodernejšou GNSS technológiou.
  </p>
);

const Address = (
  <>
    <p>GeoReal Prešov s.r.o.</p>
    <p>IČO: 44607091</p>
    <p>Konštantínova 3</p>
    <p>080 01 Prešov</p>
  </>
);

const Phone = <a href="tel:+421907405754">+421 907 405 754</a>;
const Mail = <a href="mailto:georealpo@georealpo.sk">georealpo@georealpo.sk</a>;

const Footer = () => (
  <section className="own-footer pb-5 mb-5">
    <Container>
      <Row className="pt-5 pb-4">
        <Col md={3}>
          <img src={logo} width={150} height={84} alt="logo" />
          {About}
        </Col>
        <Col md={5}>
          <h3>Kontakt</h3>
          <FooterContact img={place} text={Address} imgAlt="adresa" />
          <FooterContact img={smartphone} text={Phone} imgAlt="telefón" />
          <FooterContact img={mail} text={Mail} imgAlt="email" />
        </Col>
        <Col md={4}>
          <h3>MAPA</h3>
          <GoogleMaps latitude={48.9985899} longitude={21.2414498} />
        </Col>
      </Row>
    </Container>
  </section>
);

export default Footer;
