import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const CopyRight = () => (
  <section className="copyright-section pt-2 pb-2">
    <Container>
      <Row>
        <Col md={6} className="copyright-lefttext">
          <p>2020 GeoReal</p>
        </Col>
        <Col md={6} className="copyright-righttext">
          <p>
            Designed and developed by <strong>Branislav Osif</strong>
          </p>
        </Col>
      </Row>
    </Container>
  </section>
);

export default CopyRight;
