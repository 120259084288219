import React from 'react';
import Card from 'react-bootstrap/Card';
import { Col } from 'react-bootstrap';

// eslint-disable-next-line no-unused-vars
const ServicesTile = ({ title, description, img }) => (
  <Col md={4} className="pt-4 trans">
    <Card className="products-card mb-4 pt-5">
      <Card.Body>
        <Card.Img width={52} height={52} variant="top" src={img} alt={title} />
        <Card.Title className="pt-4 pb-3" style={{ textAlign: 'center', color: '#E20000' }}>
          {title}
        </Card.Title>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
    </Card>
  </Col>
);

export default ServicesTile;
