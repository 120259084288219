import React from 'react';
import { Col } from 'react-bootstrap';

const Headers = ({ title, coloor }) => (
  <Col className="pb-5" md={12}>
    <h2 style={{ color: coloor }}>{title}</h2>
  </Col>
);

export default Headers;
