import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import Button from 'react-bootstrap/Button';

import GDPR from '../images/GDPR-Georeal.pdf';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
};

const ContactForm = () => {

  const [fields, setFields] = useState({});
  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity()) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        // eslint-disable-next-line react/destructuring-assignment
        body: encode({ 'form-name': 'contact', ...fields }),
      })
        // eslint-disable-next-line no-undef
        .then(() => alert('Odoslané!'))
        // eslint-disable-next-line no-undef
        .catch((error) => alert(error));
      // eslint-disable-next-line no-restricted-globals,no-undef
      location.reload();
    }

    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
  };

  const switchLabel = (
    <p>
      Súhlasím so spracovaním osobných údajov a prečítal som si{' '}
      <a style={{ color: '#E20000' }} href={GDPR} target="_blank" rel="noopener noreferrer">
        Zásady spracovania osobných údajov.
      </a>
    </p>
  );



    return (
      <Form
        id="contact"
        name="contact"
        data-netlify="true"
        className="form-section"
        data-netlify-honeypot="bot-field"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="bot-field" />
        <Form.Row className="form-component justify-content-end align-content-center pt-5 pb-5">
          <Col md={12}>
            <h2 className="form-heading mb-5">Máte záujem?</h2>
          </Col>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Control
              onChange={handleChange}
              value={fields.name}
              className="credentials-input"
              required
              name="name"
              type="text"
              placeholder="Meno a priezvisko"
            />
            <Form.Control.Feedback type="invalid">Nesmie byť prázdne</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Control
              onChange={handleChange}
              className="credentials-input"
              required
              value={fields.email}
              name="email"
              type="email"
              placeholder="Email"
            />
            <Form.Control.Feedback type="invalid">Nesprávny e-mail.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Control
              onChange={handleChange}
              className="credentials-input"
              type="text"
              placeholder="Obec"
              required
              value={fields.city}
              name="city"
            />
            <Form.Control.Feedback type="invalid">Nesmie byť prázdne</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Control
              onChange={handleChange}
              className="credentials-input"
              type="tel"
              placeholder="Telefón"
              required
              value={fields.phone}
              name="phone"
            />
            <Form.Control.Feedback type="invalid">Nesmie byť prázdne</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="validationCustom04">
            <Form.Control
              onChange={handleChange}
              className="message-input"
              type="text"
              placeholder="Obsah správy"
              required
              value={fields.message}
              name="message"
            />
            <Form.Control.Feedback type="invalid">Nesmie byť prázdne</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12">
            <FormCheck
              onChange={handleChange}
              name="gdpr"
              type="switch"
              id="gdprSwitch"
              checked={fields.gdpr}
              required
              label={switchLabel}
              feedback="Musíte súhlasiť"
            />
          </Form.Group>
          <Button className="submit-button" value="Submit" type="submit">
            Odoslať
          </Button>
        </Form.Row>
      </Form>
    );
  };

export default ContactForm;
