import React from 'react';
import NavBar from '../includes/NavBar';
import Footer from '../includes/Footer';
import CopyRight from '../CopyRight';

const Layout = ({ children }) => {
  return (
    <>
      <NavBar />
      <main>{children}</main>
      <Footer />
      <CopyRight />
    </>
  );
};

export default Layout;
