import React from 'react';
import { Container, Row } from 'react-bootstrap';
// import Slider from 'react-slick';
import Headers from './includes/Headers';
import ProjectsRow from './ProjectsRow';
import img1 from '../images/projects/zalozenie.svg';
import img2 from '../images/projects/plan.svg';
import img3 from '../images/projects/zakaznik.svg';
import img4 from '../images/projects/zameranie.svg';
// import FaqCards from './FaqCards';
import Marker from './includes/Marker';

// const faqText =
//   '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ex magna aliqua. Ut enim illum ad minim veniam, quis ea exercitation ullamco cillum ut enim dolore.”';

// const settings = {
//   arrows: false,
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 2,
//   slidesToScroll: 2,
//   autoplay: true,
//   autoplaySpeed: 6000,
//   pauseOnHover: true,
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         infinite: true,
//         dots: true,
//       },
//     },
//   ],
// };

const ProjectsFaq = () => (
  <>
    <section id="projects" className="products-bg" style={{ paddingBottom: '7rem' }}>
      <Container>
        <Row>
          <Marker description="NAŠE PROJEKTY" coloor="white" />
          <Headers title="Naša spoločnosť má za sebou veľa úspechov" coloor="white" />
          <ProjectsRow title="2009" description="Založenie spoločnosti" img={img1} />
          <ProjectsRow title="1000+" description="Geometrických plánov" img={img2} />
          <ProjectsRow title="100+" description="Spokojných zákazníkov ročne" img={img3} />
          <ProjectsRow title="100 000+" description="Zameraných bodov" img={img4} />
        </Row>
      </Container>
    </section>
    {/* <section id="faq" className="faq-bg"> */}
    {/* <Container>
        <Row>
          <Col md={3}>
            <Marker description="ČASTO KLADENÉ OTÁZKY" coloor="#E20000" />
            <h2>Často sa nás pýtate tieto otázky</h2>
          </Col>
          <Col md={9} className="faq-row trans"> */}
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    {/* <Slider {...settings}>
              <FaqCards title="Otázka 1" description={faqText} />
              <FaqCards title="Otázka 2" description={faqText} />
              <FaqCards title="Otázka 3" description={faqText} />
            </Slider>
          </Col>
        </Row>
      </Container> */}
    {/* </section> */}
  </>
);

export default ProjectsFaq;
