import React from 'react';
import { Col, Row } from 'react-bootstrap';

const FooterContact = ({ img, imgAlt, text }) => (
  <Row>
    <Col md={1}>
      <img src={img} style={{ paddingRight: '2em', whiteSpace: 'nowrap' }} alt={imgAlt} />
    </Col>
    <Col md={11}>{text}</Col>
  </Row>
);

export default FooterContact;
